<template>
  <BlockUI class="block" :blocked="loadingDonut" v-if="identity.roleId!==7">
    <div class="card">
      <div class="flex justify-content-center" v-if="loadingDonut">
        <ProgressSpinner class="spinner"/>
      </div>
      <div v-if="!loadingDonut">
        <h5 class="align-self-start">Ventas por producto</h5>
        <div class="donut-card">
          <Chart
              type="doughnut"
              :data="donutData"
              :options="lightOptions"
              class="donut-chart"
          />
          <div v-if="donutData && donutData.labels">
            <DataTable
                :value="donutData.labels"
                class="p-datatable-sm"
            >
              <Column field="colour" header="" :headerStyle="legendHeaderStyle">
                <template #body="{data}">
                  <div class="legend-square" :style="'background-color: ' + data.colour + ' !important'"></div>
                </template>
              </Column>
              <Column field="product" :sortable=true header="Producto" :headerStyle="legendHeaderStyle"></Column>
              <Column field="sale" :sortable=true header="Venta" :headerStyle="legendHeaderStyle"
                      bodyStyle="text-align: right;">
                <template #body="{data}">
                  {{ formatCurrency(data.sale) }}
                </template>
              </Column>
              <Column field="percent" :sortable=true header="%" :headerStyle="legendHeaderStyle"
                      bodyStyle="text-align: right;"></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </BlockUI>
</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import SaleService from "@/service/backend/SaleService";

export default {
  name: "DonutSales",
  mixins: [generalMixin],
  created() {
    this.saleService = new SaleService();
    this.identity = this.getIdentity();
  },
  data() {
    return {
      identity: null,
      donutData: null,
      loadingDonut: false,
      legendHeaderStyle: 'background-color: var(--surface-a); color: var(--surface-900)',
      lightOptions: {
        plugins: {
          legend: {
            display: false,
            position: 'right',
            labels: {
              color: '#495057'
            }
          }
        }
      },
    }
  },
  async mounted() {
    this.loadingDonut = true;
    this.donutData = await this.saleService.getDonut({
      identity: this.identity,
      fecha: this.getYesterdayString()
    });
    this.loadingDonut = false;
  }
}
</script>

<style scoped lang="scss">

.donut-card {
  display: flex;
  justify-content: space-around;
}

.donut-chart {
  width: 350px;
  height: 350px;
}

@media only screen and (max-width: 700px) {
  .donut-chart {
    width: unset;
    height: unset;
  }
}

@media only screen and (max-width: 1800px) {
  .donut-card {
    display: unset;
    justify-content: unset;
  }
}
</style>