<template>
  <div class="card">
    <div class="flex justify-content-center" :block="true" v-if="loadingPremios">
      <ProgressSpinner class="spinner"/>
    </div>

    <div class="flex justify-content-between mb3">
      <div>
        <span class="block text-900 font-medium mb-3">{{ title }}</span>
        <div type="button" v-if="data">
          <Prizes
              :data="data.slice(0, 50)"

          />
        </div>
        <OverlayPanel ref="op" :dismissable="true">
          <DataTable :value="data"
                     dataKey="id"
                     :paginator="true"
                     :rows="20"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm"
                     responsiveLayout="scroll">
            <Column field="tproductoxsorteo" header="Sorteo" :headerStyle="legendHeaderStyle"></Column>
            <Column field="nnumero_ganador" header="Número Ganador" :headerStyle="legendHeaderStyle"
                    bodyStyle="text-align: center;">
              <template #body="{data}">
                {{ data.nnumero_ganador ? getSignValue(data) : null }}
              </template>
            </Column>
          </DataTable>
        </OverlayPanel>
      </div>

      <div>
        <Button class="p-button-raised p-button-rounded" icon="pi pi-eye" @click="toggle" style="min-wwidth: 35px;"/>
      </div>

    </div>
  </div>
</template>

<script>
import Prizes from "@/components/misc/Prizes/Prizes";
import generalMixin from "@/mixins/generalMixin";

export default {
  name: "PrizeCard",
  mixins: [generalMixin],
  components: {
    "Prizes": Prizes,
  },
  props: {
    title: String,
    data: Object
  },
  data() {
    return {
      legendHeaderStyle: 'background-color: var(--surface-a); color: var(--surface-900)',
    }
  },
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    }
  }
}
</script>

<style scoped>

</style>