<template>
  <BlockUI class="block" :blocked="loadingHistoric" v-if="identity.entityId===null && identity.roleId!==7">
    <div class="card">
      <div class="flex justify-content-center" v-if="loadingHistoric">
        <ProgressSpinner class="spinner"/>
      </div>
      <div v-if="!loadingHistoric">
        <h5>Histórico de Ventas</h5>
        <Chart type="line" :data="lineData" :options="lineOptions"/>
      </div>
    </div>
  </BlockUI>
</template>

<script>
import SaleService from "@/service/backend/SaleService";
import generalMixin from "@/mixins/generalMixin";

export default {
  name: "HistoricSales",
  mixins: [generalMixin],
  created() {
    this.saleService = new SaleService();
    this.identity=this.getIdentity();
  },
  data() {
    return {
      identity: null,
      loadingHistoric: false,
      lineOptions: null,
      lineData: null,
    }
  },
  async mounted() {
    this.loadingHistoric = this.identity.entityId === null;
    if (this.identity.entityId === null) {
      this.lineData = await this.saleService.getHistoricLine({identity: this.identity});
      this.loadingHistoric = false;
    }
    this.loadingHistoric = false;
  }

}
</script>

<style scoped>

</style>