import BaseService from "./BaseService";

class StatsService extends BaseService {

  // Charts
  async getNumbers(req) {
    const response = await this.axios.post('/stats/numbers', req);
    return response.data;
  }

  async getDbStats(req) {
    const response = await this.axios.get('/stats/database', req);
    return response.data;
  }


}

export default StatsService;
