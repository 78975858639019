<template>
  <div>
    <div v-if="data && data.numbers">


      <span class="">{{ label }}</span>

      <div class="flex justify-content-between">

        <div class="numbers">
          <div class="flex">
            <div v-for="(value, key) in data.numbers" :key="key">
              <div class="ball">{{ getValue(value) }}</div>
            </div>
          </div>
        </div>


        <div class="flex justify-content-between bar">
          <div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:15px">
            <div :class="'bg-'+color+'-500 h-full'" :style="data && 'width: '+data.percentage+'%'"></div>
          </div>
          <span :class="'text-'+color+'-500 ml-3 font-medium percent'"
                style="width:20px;text-align: right !important;">{{ data && data.percentage }}%</span>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import generalMixin from "@/mixins/generalMixin";

export default {
  name: "Bars",
  mixins: [generalMixin],
  props: {
    label: String,
    data: Object,
    color: String,
    type: String,
  },
  methods: {
    getValue(value) {
      if (this.type === 'triples-signo' || this.type === 'terminales-signo') {
        value = value.substring(0, 3) + '-' + this.getSign(value.slice(-1)).substring(0, 3);
      }
      return value;
    }
  }
}
</script>

<style scoped>
.ball {
  padding: 5px 10px 5px 10px;
  margin: 5px;
  border: solid 1px black;
  border-radius: 10px;
  font-weight: bold;
}

.bar {
  padding: 5px 10px 5px 10px;
  margin: 5px;
}

@media only screen and (max-width: 1800px) {
  .bar {
    padding: 5px 10px 5px 10px;
    margin: 5px;
  }
}

</style>