<template>
    <div class="card" v-if="identity.roleId!==7">

      <div class="flex justify-content-center" v-if="loadingNumbers">
        <ProgressSpinner class="spinner"/>
      </div>
      <div v-if="!loadingNumbers">
        <div class="flex justify-content-between align-items-center mb-5">
          <h5>Números más vendidos</h5>
        </div>
        <div>
          <Bars
              label="Triples"
              type="triples"
              :data="numbersData && numbersData.triples"
              color="red"
          />
          <Bars
              label="Terminales"
              type="terminales"
              :data="numbersData && numbersData.terminales"
              color="green"
          />
          <Bars
              label="Triples con signo"
              type="triples-signo"
              :data="numbersData && numbersData.triples_signo"
              color="purple"
          />
          <Bars
              label="Terminales con signo"
              type="terminales-signo"
              :data="numbersData && numbersData.terminales_signo"
              color="teal"
          />
          <Bars
              label="Animalitos"
              type="animalitos"
              :data="numbersData && numbersData.animalitos"
              color="orange"
          />
        </div>
      </div>
    </div>
</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import Bars from "@/components/misc/Bars/Bars";
import StatsService from "@/service/backend/StatsService";

export default {
  name: "MostSold",
  mixins: [generalMixin],
  components: {
    "Bars": Bars,
  },
  created() {
    this.statsService = new StatsService();
    this.identity=this.getIdentity();
  },
  data() {
    return {
      identity: null,
      loadingNumbers: false,
      numbersData: null,
    }
  },
  async mounted() {
    this.loadingNumbers = true;
    this.numbersData = await this.statsService.getNumbers({
      identity: this.identity,
      date: this.getTodayDate(),
    });
    this.loadingNumbers = false;
  }
}
</script>

<style scoped>

</style>