<template>
  <div class="grid"  v-if="identity.roleId!==7">
    <!--Triples-->
    <div class="col-12 lg:col-6 xl:col-3">
      <BlockUI class="block" :blocked="loadingPremios">
        <div v-if="!loadingPremios">
          <PrizeCard title="Resultados Triples" :data="premiosData && premiosData.triples"/>
        </div>
      </BlockUI>
    </div>
    <!--    Terminales-->
    <div class="col-12 lg:col-6 xl:col-3">
      <BlockUI class="block" :blocked="loadingPremios">
        <div v-if="!loadingPremios">
          <PrizeCard title="Resultados Terminales" :data="premiosData && premiosData.terminales"/>
        </div>
      </BlockUI>
    </div>
    <!--Triples con Signo-->
    <div class="col-12 lg:col-6 xl:col-3">
      <BlockUI class="block" :blocked="loadingPremios">
        <div v-if="!loadingPremios">
          <PrizeCard title="Resultados Triples con signo" :data="premiosData && premiosData.triplesSigno"/>
        </div>
      </BlockUI>
    </div>
    <!-- Terminales con Signo-->
    <div class="col-12 lg:col-6 xl:col-3">
      <BlockUI class="block" :blocked="loadingPremios">
        <div v-if="!loadingPremios">
          <PrizeCard title="Resultados Terminales con signo" :data="premiosData && premiosData.terminalesSigno"/>
        </div>
      </BlockUI>
    </div>
  </div>
</template>

<script>
import PrizeCard from "@/components/misc/Prizes/PrizeCard";
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";

export default {
  name: "Prizes",
  components: {
    "PrizeCard": PrizeCard,
  },
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService();
    this.identity = this.getIdentity();
  },
  data() {
    return {
      premiosData: null,
      loadingPremios: false,
    }
  },
  async mounted() {
    this.premiosData = await this.mainService.getPremios({fecha: this.getTodayDate()});
    this.loadingPremios = false;
  }
}
</script>

<style scoped>

</style>