<template>
  <div>
    <div v-if="data">
      <span class="">{{ label }}</span>
      <div>
        <div class="numbers">
          <div class="flex flex-wrap" >
            <div v-for="(value, key) in data" :key="key">
              <div class="ball" v-if="value.nnumero_ganador!==null">{{ getSignValue(value) }}</div>
              <!--              <div style="font-size: 10px">{{ value.tproductoxsorteo }}</div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import generalMixin from "@/mixins/generalMixin";

export default {
  name: "Prizes",
  mixins: [generalMixin],
  props: {
    label: String,
    data: Object,
    color: String,
    type: String,
  },

  methods: {

  }
}
</script>

<style scoped>
.ball {
  padding: 5px 10px 5px 10px;
  margin: 5px;
  border: solid 1px black;
  border-radius: 10px;
  font-weight: bold;
}

.bar {
  padding: 5px 10px 5px 10px;
  margin: 5px;
}

@media only screen and (max-width: 1800px) {
  .bar {
    padding: 5px 10px 5px 10px;
    margin: 5px;
  }
}

</style>